import {Paper, Typography} from "@material-ui/core";
import styled from "styled-components";

export const ContentSearhExtract = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DownloadTextStyled = styled(Typography)`
  cursor: pointer;
  color: #2392ea;
`;

export const ImgStyled = styled.img`
  border-radius: 25px;
  width: 200px;
`;

export const H3Styled = styled.h3`
  text-align: center;
  color: #2392ea;
  font-size: 32px;
`;

export const P1Styled = styled.p`
  text-align: center;
  font-size: 32px !important;
  font-weight: bold;
`;

export const ContentImgStyled = styled.p`
  text-align: center;
`;

export const MainContentStyled = styled.div`
    padding: 40px;
  
  p {
    font-size: 18px !important;
    font-weight: bold;
  }
`;

export const PrintButtonStyled = styled(Paper)`
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: fixed;
  z-index: 99999;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #ffffff;
  color: deepskyblue;

  svg {
    font-size: 36px;
  }

  cursor: pointer;

  @media print {
    .noPrint {
      display: none;
    }
  }
`;
