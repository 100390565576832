import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Button, Typography} from "@material-ui/core";
import MessageUtil from "../../../Utils/MessageUtil";
import AwardService from "../../../Services/AwardService";
import PdfExtractPage from "../ExtractScreen/PdfExtractPage";
import PointsComponent from "./PointsComponent";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import ExtractService from "../../../Services/ExtractService";
import InputFileDefault from "../../../Components/InputFileDefault/InputFileDefault";

import csvImage from "../../../Assets/csv-file.png"
import {ContentFileCSVStyled, CSVImgStyle} from "../CompanyRegistrationScreen/styled";
import CheckPointsComponent from "./CheckPointsComponent";
import CursorComponent from "../../../Components/CursorComponent/CursorComponent";

class ManualScoringScreen extends Component {
    static ROUTE = '/manual-scoring';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            award: null,
            btnAlterDisabled: false,
            csvModelBase64: null,
            csvModelName: null,
            file: null,
            releaseResponse: null,
            isCSV: false,
        };

        this.extractService = new ExtractService();
    }

    confirmRelease = () => {
        const contextAux = this;
        this.setState({
            messagens: MessageUtil.make({
                title: 'Tem certeza que deseja pontuar?',
                okButtonText: 'Sim',
                body: 'Essa ação não poderá ser desfeita.',
                okButtonFunction: contextAux.doFinishRelease,
                cancelButtonText: 'Não',
            }),
        });
    }

    doFinishReleaseCheck = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            const cpf = [];
            const points = [];
            this.state.values.map((values) => {
                cpf.push(values.cpf);
                points.push(values.points);
            });

            const response = await this.extractService.scoringManualCheck(
                {
                    cpf: cpf,
                    points: points,
                }
            );

            this.setState({
                inLoad: false,
                releaseResponse: response.data.data,
                isCSV: false,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doFinishRelease = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            const cpf = [];
            const points = [];
            this.state.values.map((values) => {
                cpf.push(values.cpf);
                points.push(values.points);
            });

            await this.extractService.scoringManual(
                {
                    cpf: cpf,
                    points: points,
                }
            );

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'PONTUAÇÃO ATRIBUÍDA COM SUCESSO',
                    body: '',
                    okBtnToRoute: ManualScoringScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    getModelCSV = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.extractService.getModelCSV();

            this.setState({
                inLoad: false,
            });

            window.open(response.data.file, '_blank');

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    confirmReleaseCSV = () => {
        const contextAux = this;
        this.setState({
            messagens: MessageUtil.make({
                title: 'Tem certeza que deseja pontuar?',
                okButtonText: 'Sim',
                body: 'Essa ação não poderá ser desfeita.',
                okButtonFunction: contextAux.doFinishReleaseCSV,
                cancelButtonText: 'Não',
            }),
        });
    }

    doFinishReleaseCSVCheck = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.extractService.scoringManualCheck(
                {
                    file: this.state.file.split(',')[1],
                }
            );

            this.setState({
                inLoad: false,
                releaseResponse: response.data.data,
                isCSV: true,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doFinishReleaseCSV = async () => {
        try {

            this.setState({
                inLoad: true,
            });

            const response = await this.extractService.scoringManual(
                {
                    file: this.state.file.split(',')[1],
                }
            );

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'PONTUAÇÃO ATRIBUÍDA COM SUCESSO',
                    body: '',
                    okBtnToRoute: ManualScoringScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    closeCheck = () => {
        this.setState({
            releaseResponse: null,
        });
    }


    render() {
        return (
            <div>
                <HeaderComponent messagens={this.state?.messagens}
                                 title={'Pontuação Manual'}
                                 inLoad={this.state.inLoad}
                                 menu={true}/>
                <Typography align={'center'}
                            variant={'h6'}
                            color={'primary'}>
                    Pontuação Manual
                </Typography>
                <br/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'400'}>
                        <PointsComponent values={this.state}
                                         onChange={(value) => {
                                             this.setState({...this.state, ...value});
                                             this.setState({
                                                 btnAlterDisabled: true
                                             });
                                         }}/>
                        <SpacerComponent height={20}/>
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                disabled={!this.state.btnAlterDisabled}
                                onClick={() => this.doFinishReleaseCheck()}>
                            Pontuar
                        </Button>
                        <SpacerComponent height={50}/>
                        <Typography variant={'h6'}
                                    color={'primary'}
                                    align={'center'}>Pontuação em Lote</Typography>
                        <SpacerComponent height={20}/>
                        <ContentFileCSVStyled>
                            <CSVImgStyle src={csvImage}/>
                            <CursorComponent>
                                <Typography variant={'body2'}
                                            color={'primary'}
                                            align={'center'}
                                            onClick={this.getModelCSV}>
                                    Clique aqui para baixar o modelo da planilha
                                </Typography>
                            </CursorComponent>
                            <SpacerComponent height={20}/>
                            <InputFileDefault setPreviosImage={() => console.log()}
                                              toBase64Image={(base64) => {
                                                  this.setState({
                                                      file: base64,
                                                  });
                                              }}
                                              text={'Fazer uoload do seu arquivo'}
                                              accept={'.csv'}
                                              compression={false}
                                              onMaxSize={(value) => {
                                                  this.context.parent.setState({
                                                      messagens: MessageUtil.make({
                                                          title: 'Atenção',
                                                          body: `Arquivo não pode ter mais que ${value} mb`
                                                      }),
                                                  })
                                              }}/>
                        </ContentFileCSVStyled>
                        <SpacerComponent height={50}/>
                        <Button variant={'contained'}
                                color={'primary'}
                                size={"large"}
                                fullWidth={true}
                                disabled={!this.state.file}
                                onClick={() => this.doFinishReleaseCSVCheck()}>
                            Pontuar em Lote
                        </Button>
                    </Box>
                </Box>
                <CheckPointsComponent closeCheck={this.closeCheck}
                                      confirmRelease={this.confirmRelease}
                                      data={this.state.releaseResponse}
                                      isCSV={this.state.isCSV}
                                      confirmReleaseCSV={this.confirmReleaseCSV}/>
            </div>
        );
    }
}

ManualScoringScreen.propTypes = {};

export default ManualScoringScreen;
