import React, {Component} from 'react';
import {IconButton} from "@material-ui/core";
import {SearchTextFieldStyled} from "./styled";
import {SearchOutlined} from "@material-ui/icons";
import {ExtractContext} from "../../Contexts/ExtractContext";
import {DatePicker} from "@material-ui/pickers";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import {ContentSearhExtract} from "../../Screens/PJAccess/ExtractScreen/styled";
import DateUtil from "../../Utils/DateUtil";

class SearchTextFieldComponent extends Component {
    static contextType = ExtractContext;

    constructor(props) {
        super(props);

        this.state = {
            filter: null,
            selectedDateInitial: DateUtil.getDateRangeToDate(7).initial,
            selectedDateInitialApi: DateUtil.getDateRangeFormat(7).initial,
            selectedDateFinal: DateUtil.getDateRangeToDate(7).final,
            selectedDateFinalApi: DateUtil.getDateRangeFormat(7).final,
        };
    }

    handleChange = (event) => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    handleChangeDate(name, value) {

        const apiState = name + 'Api';

        this.setState({
            [name]: value.toDate(),
            [apiState]: value.format('YYYY-MM-DD'),
        });
    }

    render() {
        return (
            <ContentSearhExtract>
                <SearchTextFieldStyled id="outlined-basic"
                                       label="Buscar"
                                       variant="outlined"
                                       name={'term'}
                                       onChange={this.handleChange}/>
                <SpacerComponent width={20}/>
                <DatePicker label={'Data Inicial'}
                            value={this.state.selectedDateInitial}
                            onChange={(date) => this.handleChangeDate('selectedDateInitial', date)}
                            animateYearScrolling={true}
                            format="DD/MM/yyyy"/>
                <SpacerComponent width={20}/>
                <DatePicker label={'Data Final'}
                            value={this.state.selectedDateFinal}
                            onChange={(date) => this.handleChangeDate('selectedDateFinal', date)}
                            animateYearScrolling={true}
                            format="DD/MM/yyyy"/>
                <SpacerComponent width={20}/>
                <IconButton
                    onClick={() => this.context.doSearch(
                        this.state,
                    )}>
                    <SearchOutlined/>
                </IconButton>
            </ContentSearhExtract>
        );
    }
}

export default SearchTextFieldComponent;