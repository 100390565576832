import React, {Component} from 'react';
import {Box, Button, Hidden} from "@material-ui/core";
import AvatarCircleComponent from "../../../../Components/AvatarCircleComponent/AvatarCircleComponent";
import SearchTextFieldComponent from "../../../../Components/SearchTextFieldComponent/SearchTextFieldComponent";
import SpacerComponent from "../../../../Components/SpacerComponent/SpacerComponent";

class SubHeaderOperator extends Component {
    render() {
        return (
            <div>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Hidden xsDown={true}
                            smDown={true}>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             justifyContent={'flex-start'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent width={20}/>
                            <SearchTextFieldComponent/>
                        </Box>
                        <Box display={'flex'}
                             flexDirection={'row'}
                             justifyContent={'flex-start'}
                             alignItems={'center'}>
                            <Button variant={'outlined'}
                                    color={'primary'}
                                    onClick={this.props.controllerAddOperator}>Adicionar Operador</Button>
                        </Box>
                    </Hidden>
                    <Hidden lgUp={true}
                            xlUp={true}
                            mdUp={true}>
                        <Box display={'flex'}
                             flexDirection={'column'}
                             justifyContent={'center'}
                             alignItems={'center'}
                             flex={1}>
                            <AvatarCircleComponent/>
                            <SpacerComponent height={20}/>
                            <SearchTextFieldComponent/>
                            <SpacerComponent height={20}/>
                            <Button variant={'outlined'}
                                    color={'primary'}
                                    onClick={this.props.controllerAddOperator}>Adicionar Operador</Button>
                        </Box>
                    </Hidden>
                </Box>
            </div>
        );
    }
}

export default SubHeaderOperator;