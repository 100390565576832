import React, {Component} from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {ContentChartStyled, TableStyleContent} from "./styled";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import DateUtil from "../../../Utils/DateUtil";

class PrizesReserved extends Component {
    constructor(props) {
        super(props);
    }

    makeRowTable() {
        const rows = [];

        var count = 1;
        Object.values(this.props.data).forEach((element) => {
            count++;
            rows.push(
                <TableRow key={count}>
                    <TableCell>{element.award}</TableCell>
                    <TableCell>{element.amount}</TableCell>
                </TableRow>
            );

        });

        return rows;
    }

    render() {
        return (
            <Grid item={true}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}>
                <ContentChartStyled elevation={3}>
                    <Typography variant={'h6'}
                                color={'primary'}>
                        Prêmios Reservados
                    </Typography>
                    <SpacerComponent height={20}/>
                    <TableStyleContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Quantidade</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.makeRowTable()}
                            </TableBody>
                        </Table>
                    </TableStyleContent>
                </ContentChartStyled>
            </Grid>
        );
    }
}

export default PrizesReserved;