import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Typography} from "@material-ui/core";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import SubHeaderOperator from "./SubHeaderOperator/SubHeaderOperator";
import TableOperator from "./TableOperator";
import CreateOperator from "./CreateOperator";
import MessageUtil from "../../../Utils/MessageUtil";
import OperatorValidation from "../../../FormValidations/OperatorValidation";
import CompanyService from "../../../Services/CompanyService";
import OperatorService from "../../../Services/OperatorService";
import {OperatorContext} from "../../../Contexts/OperatorContext";
import UserService from "../../../Services/UserService";
import {SuperContext} from "../../../Contexts/SuperContext";
import GeneralTranslation from "../../../Translations/FormValidations/GeneralTranslation";


class OperatorScreen extends Component {
    static ROUTE = '/operator';
    static contextType = SuperContext;

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            addOperatorOpen: false,
            companies: [],
            operators: [],
            valueEdit: null,
        };

        this.companyService = new CompanyService();
        this.operatorSevice = new OperatorService();
        this.userService = new UserService();

    }

    componentDidMount() {
        this.load();
    }

    async load(search = null) {
        try {

            this.setState({
                inLoad: true,
            })

            const operators = await this.operatorSevice.getByMatrix(search);
            const response = await this.companyService.getByMatrix();

            this.setState({
                inLoad: false,
                operators: operators.data.data,
                companies: response.data.data,
            })

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    controllerAddOperator = () => {
        this.setState({
            addOperatorOpen: !this.state.addOperatorOpen,
            valueEdit: null,
        });
    }

    doCreate = async (object) => {
        try {
            const validate = OperatorValidation.validate(object);

            if (validate !== true) {
                this.setState({
                    erros: validate,
                    messagens: MessageUtil.makeToValidationField(
                        new GeneralTranslation(validate).translate()
                    ),
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.operatorSevice.create(object);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Operador cadastrado',
                    okBtnToRoute: OperatorScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doDeleteConfirm = async (uuid) => {

        const context = this;

        this.setState({
            inLoad: false,
            messagens: MessageUtil.make({
                title: 'Atenção',
                body: 'Tem certeza que quer excluir este Operador? Esta ação não poderá ser desfeita.',
                okButtonText: 'Sim',
                okButtonFunction: function (value) {
                    context.doDelete(uuid);
                },
                cancelButtonText: 'Cancelar'
            }),
        });

    }

    doDelete = async (uuid) => {
        try {

            this.setState({
                inLoad: true,
            });

            await this.operatorSevice.delete(uuid);

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Operador deletado',
                    okBtnToRoute: OperatorScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doEdit = async (value) => {
        try {
            this.setState({
                valueEdit: value,
                addOperatorOpen: true,
            });
        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doSearch = (search) => {

        this.load(search);

    }

    doEditFinish = async (value) => {
        try {
            const validate = OperatorValidation.validate(value);

            if (validate !== true) {
                this.setState({
                    erros: validate,
                });
                return;
            }

            this.setState({
                inLoad: true,
            });

            await this.userService.update(
                value.uuid,
                value
            );

            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Sucesso',
                    body: 'Operador cadastrado',
                    okBtnToRoute: OperatorScreen.ROUTE,
                }),
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    render() {
        return (
            <OperatorContext.Provider value={{
                doSearch: this.doSearch,
            }}>
                <div>
                    <HeaderComponent title={'Operador de Loja'}
                                     messagens={this.state?.messagens}
                                     inLoad={this.state.inLoad}
                                     menu={true}/>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         alignItems={'center'}>
                        <Box width={'80%'}>
                            <Typography align={'center'}
                                        variant={'h6'}
                                        color={'primary'}>
                                Operadores de Loja
                            </Typography>
                            <SpacerComponent height={20}/>
                            <SubHeaderOperator controllerAddOperator={this.controllerAddOperator}/>
                            <SpacerComponent height={40}/>
                            <CreateOperator addOperatorOpen={this.state.addOperatorOpen}
                                            controllerAddOperator={this.controllerAddOperator}
                                            doCreate={this.doCreate}
                                            erros={this.state.erros}
                                            companies={this.state.companies}
                                            valueEdit={this.state.valueEdit}
                                            doEditFinish={this.doEditFinish}/>
                            <TableOperator doDeleteConfirm={this.doDeleteConfirm}
                                           doEdit={this.doEdit}
                                           operators={this.state.operators}/>
                        </Box>
                    </Box>
                </div>
            </OperatorContext.Provider>
        );
    }
}

export default OperatorScreen;