import React, {Component} from 'react';
import HeaderComponent from "../../../Components/HeaderComponent/HeaderComponent";
import {Box, Typography} from "@material-ui/core";
import SubHeaderExtract from "./SubHeaderExtract/SubHeaderExtract";
import SpacerComponent from "../../../Components/SpacerComponent/SpacerComponent";
import TableExtract from "./TableExtract";
import MessageUtil from "../../../Utils/MessageUtil";
import ExtractService from "../../../Services/ExtractService";
import MeUtil from "../../../Utils/MeUtil";
import {ExtractContext} from "../../../Contexts/ExtractContext";
import ErrorUtil from "../../../Utils/ErrorUtil";
import DateUtil from "../../../Utils/DateUtil";


class ExtractScreen extends Component {
    static ROUTE = '/extract';

    constructor(props) {
        super(props);

        this.state = {
            inLoad: false,
            erros: [],
            extractList: [],
        };

        this.extractService = new ExtractService();
    }

    componentDidMount() {
        this.load({});
    }

    async load({
                   term = '',
                   initial = DateUtil.getDateRangeFormat(900).initial,
                   final = DateUtil.getDateRangeFormat(7).final
               }) {
        try {
            console.log(initial, final);
            if (initial > final) {
                throw ErrorUtil.make(
                    'A data inicial não pode ser maior que a final'
                );
            }

            this.setState({
                inLoad: true,
            });

            const me = await MeUtil.get();

            const response = await this.extractService.getByCompany({
                companyUuid: me.data.data.company.uuid,
                term,
                initial,
                final,
            });

            if (response.data[0] === undefined) {
                response.data = [];
            }

            this.setState({
                inLoad: false,
                extractList: response.data,
                initial,
                final,
            });

        } catch (e) {
            this.setState({
                inLoad: false,
                messagens: MessageUtil.make({
                    title: 'Atenção',
                    body: e,
                }),
            });
        }
    }

    doSearch = (filter) => {

        this.load({
            term: filter.term,
            initial: filter.selectedDateInitialApi,
            final: filter.selectedDateFinalApi,
        });

    }

    render() {
        return (
            <ExtractContext.Provider value={{
                doSearch: this.doSearch,
            }}>
                <HeaderComponent title={'Extrato'}
                                 menu={true}
                                 messagens={this.state?.messagens}
                                 inLoad={this.state.inLoad}/>
                <Box display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}>
                    <Box width={'80%'}>
                        <Typography align={'center'}
                                    variant={'h6'}
                                    color={'primary'}>
                            Extrato
                        </Typography>
                        <SpacerComponent height={20}/>
                        <SubHeaderExtract/>
                        <SpacerComponent height={40}/>
                        <TableExtract extractList={this.state.extractList}/>
                        {!this.state.extractList.length &&
                            <>
                                <SpacerComponent height={120}/>
                                <Typography variant={'body1'}
                                            align={'center'}>
                                    Não foi encontrado registros para o período
                                    de <b>{DateUtil.raw(this.state.initial).toDate()}</b> à <b>{DateUtil.raw(this.state.final).toDate()}</b>
                                </Typography>
                                <SpacerComponent height={120}/>
                            </>
                        }
                    </Box>
                </Box>
            </ExtractContext.Provider>
        );
    }
}

export default ExtractScreen;